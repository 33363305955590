<template>
  <div class="px-10">
    <v-toolbar dense flat class="my-5">
      <div class="text-h4 primary--text">รายชื่อผู้จำหน่าย</div>

      <div style="width: 400px;">
        <v-text-field
          class="ml-10"
          v-model="filter.start_with"
          prepend-inner-icon="mdi-magnify"
          label="ค้นหา ชื่อ เบอร์โทร"
          outlined
          rounded
          dense
          single-line
          hide-details
          @keyup="getData()"
          item-key="_id"
        ></v-text-field>
      </div>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        outlined
        small
        class="ml-5"
        @click="(dealer_id = ''), (dialogUpdate = true)"
      >
        <v-icon left small>mdi-plus</v-icon> เพิ่มรายชื่อ
      </v-btn>
    </v-toolbar>

    <v-data-table
      class="elevation-2 row-pointer"
      :headers="headers"
      :items="list"
      :loading="loading"
      sort-by="calories"
      :loading-text="$table_loading_text"
      :page.sync="filter.page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
      item-key="_id"
      @click:row="edit"
    >
      <!--
      <template v-slot:[`item.status`]="{ item }">
        <menu-ship-document small v-model="item.status"/>
      </template>-->
      <template v-slot:[`item.index`]="{ item }">
        {{
          list
            .map(function(x) {
              return x._id;
            })
            .indexOf(item._id) +
            1 +
            filter.item_per_page * (filter.page - 1)
        }}
        <!-- {{ ((filter.page-1) * filter.item_per_page) + props.index + 1 }} -->
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="grey" small outlined rounded>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template>
              <v-list-item dense link @click="edit(item)">
                <v-list-item-title>แก้ไข</v-list-item-title>
              </v-list-item>
              <v-list-item dense link @click="remove(item)">
                <v-list-item-title>ลบ</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>

      <!--
      <template v-slot:footer>
        <table-pagination :page.sync="page" :itemsPerPage.sync="itemsPerPage" :length="pageCount"/>
      </template>
      -->
      <template v-slot:footer>
        <table-pagination-custom
          :page="filter.page"
          :itemsPerPage="filter.item_per_page"
          :length="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>
    </v-data-table>

    <v-dialog persistent scrollable max-width="90%" v-model="dialogUpdate">
      <Detail
        :id="dealer_id"
        @onClose="dialogUpdate = false"
        @onSave="
          () => {
            dialogUpdate = false;
            onChangeitemsPerPage(filter.item_per_page);
          }
        "
      />
    </v-dialog>
  </div>
</template>

<script>
import Detail from '@/views/pages/expenses/dealer/Detail.vue';

export default {
  components: {
    Detail
  },
  data: () => ({
    search: '',
    loading: true,
    pageCount: 0,

    headers: [
      { text: 'ลำดับ', value: 'index', align: 'center' },
      { text: 'ชื่อ', value: 'name' },
      { text: 'ผู้ติดต่อ', value: 'contact_people', filterable: false },
      { text: 'เบอร์โทร', value: 'contact_tel' },
      { text: '', value: 'actions', sortable: false, filterable: false }
    ],
    list: [],
    /** */
    dialogUpdate: false,
    /** *
    dialogDetail: false,
    dialogDetailUpdate: true,*/
    filter: {
      item_per_page: 20,
      page: 1,
      start_with: ''
    },
    dealer_id: ''
  }),

  created() {
    this.getData();
  },
  methods: {
    edit(item) {
      this.dealer_id = item._id;
      this.dialogUpdate = true;
    },
    remove(item) {
      this.$alertConfirm({ title: `ยืนยันลบข้อมูล ?`, text: `` }).then(
        async result => {
          if (result.isConfirmed) {
            let body = {
              token: this.$jwt.sign({ dealer_id: item._id }, this.$privateKey, {
                noTimestamp: true
              })
            };
            await this.$axios
              .post(`${this.$baseUrl}/dealer/delete_dealer`, body)
              .then(res => {
                this.$alertSuccess({ title: res.status });
                this.getData();
              })
              .catch(err => {
                this.$alertServerError({ title: err.error_message });
              });
          }
        }
      );
    },
    async getData() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true
        })
      };
      await this.$axios
        .post(`${this.$baseUrl}/dealer/get_dealer`, body)
        .then(res => {
          this.list = res.result.data;
          this.pageCount = res.result.total_page || 1;
        })
        .catch(err => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    onChangePage(page) {
      this.filter.page = page;
      this.getData();
    },
    onChangeitemsPerPage(number) {
      this.filter.item_per_page = number;
      this.onChangePage(1);
    }
  }
};
</script>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
